<template>
    <b-modal :visible="seeCupons" @change="(val) => $emit('update:seeCupons', val)" size="xl" title="Cupons">
        <b-table id="tablarda" :items="items" :fields="fields" hover responsive primary-key="id" class="position-relative"
            show-empty empty-text="No records found">
            <template #cell(view)="data">
                <b-form-checkbox v-model="data.detailsShowing" @change="data.toggleDetails" />
            </template>

            <template #cell(patient)="data">
                {{ data.item.patient_name }} {{ data.item.patient_lastname }}
            </template>

            <template #cell(show)="row">
                <b-form-checkbox v-model="row.detailsShowing" plain class="vs-checkbox-con" @change="row.toggleDetails" />
            </template>

            <template #row-details="row">
                <b-card>
                    <b-container>
                        <b-row>
                            <b-col cols="0">
                                <strong>Nurse:</strong>
                            </b-col>
                            <b-col>
                                <span class="font-weight-bold d-block text-nowrap">
                                    {{ row.item.nurse_name }}
                                </span>
                            </b-col>
                            <b-col>
                                <strong>Visit Date : </strong>{{ row.item.visit_date }}
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="0">
                                <strong>Oma:</strong>
                            </b-col>
                            <b-col>
                                <span class="font-weight-bold d-block text-nowrap">
                                    {{ row.item.oma_name }}
                                </span>
                            </b-col>
                            <b-col cols="0">
                                <strong>Frontdesk:</strong>
                            </b-col>
                            <b-col>
                                <span class="font-weight-bold d-block text-nowrap">
                                    {{ row.item.frontdesk_name }}
                                </span>
                            </b-col>
                        </b-row>
                        <hr />
                        <b-row>
                            <b-col>
                                <b-card title="Visit Reason: ">
                                    <span>
                                        {{ row.item.reason_visit }}
                                    </span>
                                </b-card>
                            </b-col>
                        </b-row>
                    </b-container>
                    <br />
                    <b-button class="mr-2" size="sm" variant="outline-secondary" @click="row.toggleDetails">
                        Hide Details
                    </b-button>
                    <b-button size="sm" variant="outline-secondary" @click="seeDetails(row.item)">
                        See details
                    </b-button>
                </b-card>
            </template>
        </b-table>
        <PaginationTable :dataMeta="dataMeta" :totalUsers="totalUsers" :perPage="perPage" :currentPage="currentPage"
            @page-changed="pageChanged" />
        <ToastNotification ref="toast" />
    </b-modal>
</template>

<script>
import {
    BContainer,
    BCard,
    BRow,
    BCol,
    BButton,
} from "bootstrap-vue";

import PaginationTable from "@/components/PaginationTable";
import ToastNotification from "@/components/ToastNotification";
import axios from "@/core/services/apiInsurance/admin/adminData";

export default {
    components: {
        BContainer,
        BRow,
        BCol,
        BCard,
        BButton,
        ToastNotification,
        PaginationTable
    },
    model: {
        prop: "seeCupons",
        event: "update:seeCupons",
    },
    props: {
        tableData: {
            type: Object,
            default: () => { },
            required: false
        },
        seeCupons: {
            type: Boolean,
            default: false,
            required: true
        },
    },
    watch: {
        tableData() {
            if (this.tableData) {
                const { data, current_page, from, total, to, per_page } = this.tableData.CAMPAIGN_CUPONS['30'].CUPONS_PAGINATE
                this.items = data
                this.dataMeta = { from, to, of: total }
                this.totalUsers = total
                this.perPage = per_page
                this.currentPage.page = current_page
            }
        }
    },
    data() {
        return {
            items: [],
            fields: [
                "show",
                "total",
                "ajustment",
                "cash",
                "cc",
                "consult_price",
                "patient",
                "facility",
                "type_visit",
                "visit_date",
                "pay_status",
                "visit_status",
            ],
            dataMeta: { from: 1, to: 0, of: 0 },
            totalUsers: null,
            perPage: null,
            currentPage: {
                page: 1
            },
            visit: {},
            modalDetailBool: false,
            patient_name: ''
        };
    },
    methods: {
        pageChanged(page) {
            this.$emit('paginated', page)
        },
        async seeDetails({ id, patient_name }) {
            const { office_visit } = await axios.getDetailsOfficeVisit(id)
            this.patient_name = patient_name
            this.visit = office_visit
            this.modalDetailBool = true
        },
    },
};
</script>

<style lang="scss" scoped></style>

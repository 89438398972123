<template>
  <div>
    <b-card>
      <b-row class="mb-3" align-h="between" align-v="center">
        <b-avatar size="6rem" :src="avatar" v-if="avatar" />
        <img style="width: 6rem" :src="logo" v-else />
        <h1 style="text-align: end">{{ facility }}</h1>
      </b-row>
      <b-table :items="items"></b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
} from 'bootstrap-vue'
import axios from '@/core/services/apiInsurance/admin/facilities'
import logo from '@/assets/images/Recurso 11.png'

export default {
  components: {
    BRow,
    BCard,
  },
  props: {
    datarda: {
      type: Object,
      default: () => {},
      required: false,
    },
  },
  data() {
    return {
      items: [],
      facility: 'Facilities',
      avatar: null,
      logo: logo,
    }
  },
  watch: {
    datarda() {
      if (this.datarda) {
        this.items = this.datarda.COUNT_PER_TYPE
        this.getFacility(this.datarda.facility)
      }
    },
  },
  methods: {
    async getFacility(id) {
      if (!id || !id.length) {
        this.facility = 'Facilities'
        this.avatar = null
        return
      }

      const response = await axios.facilityDetail(id)
      if (response.registro) {
        const { name, avatar } = response.registro
        this.facility = name
        this.avatar = avatar
      } else {
        this.facility = 'Facilities' // Set a default value if registro is undefined
        this.avatar = null
      }
    },
  },
}
</script>

<style scoped lang="scss">
.card {
    --main-color: #000;
    --submain-color: #78858F;
    --bg-color: #fff;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    position: relative;
    border-radius: 20px;
    background: var(--bg-color);
}
</style>
